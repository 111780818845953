import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route as ReactRoute,
  Switch,
  useHistory
} from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './styles.css';
import config from './config';
import Home from './Home';
import Map, { MapProps } from './Map';
import { Typography } from '@mui/material';
import { DispatchMode } from './type';

// AWS Amplify configuration
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.region,
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.userPoolWebClientId,
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      domain: config.cognito.domain,
      scope: ['email', 'openid', 'phone'],
      redirectSignIn: config.cognito.redirectSignIn,
      redirectSignOut: config.cognito.redirectSignOut,
      responseType: 'token'
    }
  }
});

const RoutedApp = () => {
  return (
    <Router basename={config.app.basename}>
      <App />
    </Router>
  );
};

const App = () => {
  const history = useHistory();
  const [mapProps, setMapProps] = useState<MapProps | undefined>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [redirectPath, setRedirectPath] = useState<string | undefined>();

  useEffect(() => {
    const handleAuth = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken();
        const features = accessToken.payload.features;
        let route = '';
        let mayId = '';
        if (
          accessToken.payload.vehicle &&
          !accessToken.payload.vehicle.includes('all')
        ) {
          mayId = accessToken.payload.vehicle;
        }
        if (
          accessToken.payload.route &&
          !accessToken.payload.route.includes('all')
        ) {
          route = accessToken.payload.route;
        }
        const stopIdsString = accessToken.payload.stops;
        const stopIds =
          stopIdsString && !stopIdsString.includes('all')
            ? stopIdsString.split(',')
            : [];

        const vehicleProvidedRoute = undefined;
        // Determine the redirect path based on features
        if (features.includes('demo')) {
          setMapProps({
            routeName: route,
            mayId,
            dispatchMode: DispatchMode.demo,
            stopIds,
            vehicleProvidedRoute
          });
          setRedirectPath('/demo');
          if (redirectPath) {
            history.push(redirectPath);
          }
        } else if (features.includes('rx')) {
          setMapProps({
            routeName: route,
            mayId,
            dispatchMode: DispatchMode.riderExperience,
            stopIds,
            vehicleProvidedRoute
          });
          setRedirectPath('/rx');
          if (redirectPath) {
            history.push(redirectPath);
          }
        } else {
          setRedirectPath('/');
        }

        localStorage.setItem('accessToken', JSON.stringify(accessToken));
      } catch (error) {
        console.error('Authentication error:', error);
        setIsAuthenticated(false);
        Auth.federatedSignIn(); // Trigger the Hosted UI if not authenticated
      }
    };

    handleAuth();
  }, [redirectPath]);

  if (!isAuthenticated) return <Typography>Loading...</Typography>; // Or show a loading spinner

  return (
    <Authenticator>
      {() => (
        <Switch>
          <ReactRoute
            path='/'
            exact
            render={() => <Home setMapProps={setMapProps} />}
          />
          <ReactRoute
            path='/demo'
            exact
            render={() => {
              if (
                mapProps !== undefined &&
                mapProps.dispatchMode === DispatchMode.demo
              ) {
                return <Map {...mapProps} />;
              } else {
                return <Home setMapProps={setMapProps} />;
              }
            }}
          />
          <ReactRoute
            path='/rx'
            exact
            render={() => {
              if (
                mapProps !== undefined &&
                mapProps.dispatchMode === DispatchMode.riderExperience
              ) {
                return <Map {...mapProps} />;
              } else {
                return <Home setMapProps={setMapProps} />;
              }
            }}
          />
          <ReactRoute path='/logout' component={Logout} />
        </Switch>
      )}
    </Authenticator>
  );
};

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    const signOut = async () => {
      await Auth.signOut();
      history.push('/'); // Redirect to home page after sign out
    };
    signOut();
  }, [history]);

  return <Typography>Logging out...</Typography>;
};

export default RoutedApp;
