const env = (window as any)._env_;

// Amazon Cognito Configuration
export default {
  app: {
    basename: '/'
  },
  cognito: {
    region: env.COGNITO_REGION,
    userPoolId: env.COGNITO_USER_POOL_ID,
    userPoolWebClientId: env.COGNITO_CLIENT_ID,
    domain: env.COGNITO_DOMAIN,
    redirectSignIn: env.COGNITO_SIGN_IN_REDIRECT,
    redirectSignOut: env.COGNITO_SIGN_OUT_REDIRECT
  }
};
