import axios, { AxiosResponse } from 'axios';
import { API_URL } from './constants';
import {
  ClearDispatchRequest,
  DispatchRequest,
  RouteStop,
  TaskType,
  Vehicle,
  VehicleRoute,
  VehicleStop
} from './type';

export const getVehicles = async (token?: string): Promise<Vehicle[]> => {
  const res = await axios({
    method: 'GET',
    url: API_URL + '/vehicle/list',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    responseType: 'json'
  });
  return res.data;
};

export const getVehicleProvidedRoute = async (
  vehicleId: string,
  token?: string
): Promise<VehicleRoute | undefined> => {
  const vehicleRoute = await axios({
    method: 'GET',
    url: API_URL + '/vehicle/' + vehicleId + '/route',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    responseType: 'json'
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        //This is an expected scenario as not all vehicles
        //will have stops.
        return undefined;
      } else {
        console.error('Error getting vehicle stops: ', error);
        return undefined;
      }
    });

  if (vehicleRoute === undefined) {
    return undefined;
  }

  //Sort stops in alphabetical order
  return {
    routeName: vehicleRoute.routeName,
    stops: vehicleRoute.stops.sort((a: VehicleStop, b: VehicleStop) =>
      a.stopName.toLowerCase().localeCompare(b.stopName.toLowerCase())
    )
  };
};

export const dispatchVehicle = async (
  vehicle: Vehicle,
  stop: RouteStop,
  taskType: TaskType,
  token?: string
): Promise<AxiosResponse> => {
  const body: DispatchRequest = {
    vehicleId: vehicle.vehicleId,
    stopName: stop.stop_point_id,
    latitude: parseFloat(stop.location.lat),
    longitude: parseFloat(stop.location.lng),
    taskType
  };
  try {
    return await axios({
      method: 'POST',
      url: API_URL + '/vehicle/dispatch',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      data: body
    });
  } catch (e: any) {
    return e.response;
  }
};

export const clearDispatchVehicle = async (
  vehicle: Vehicle,
  token?: string
): Promise<AxiosResponse> => {
  const body: ClearDispatchRequest = {
    vehicleId: vehicle.vehicleId,
    rideId: vehicle.odsRideId ?? ''
  };
  try {
    return await axios({
      method: 'DELETE',
      url: API_URL + '/vehicle/dispatch',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      data: body
    });
  } catch (e: any) {
    return e.response;
  }
};
