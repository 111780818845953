import { State, VehicleUpdateAction } from '../../type';
import { SET_VEHICLE_UPDATES } from '../actionTypes';

const initialState: State = {};

export default function (state = initialState, action: VehicleUpdateAction) {
  switch (action.type) {
    case SET_VEHICLE_UPDATES: {
      const newState: State = {};
      for (const vehicle of action.vehicles) {
        newState[vehicle.vehicleId] = vehicle;
      }
      return { ...newState };
    }
    default:
      return state;
  }
}
