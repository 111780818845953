import React, { useEffect, useState } from 'react';
import { Marker, MarkerProps } from 'react-map-gl';
import useInterval from '../hooks/useInterval';
import { setVehicleUpdates } from '../redux/actions';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getVehicles } from '../requests';
import { Platform, Vehicle } from '../type';
import AvSiennaIcon from './AvSiennaIcon';
import { Auth } from 'aws-amplify';

const VehicleMarker = (props: VehicleMarkerProps) => {
  const { vehicle } = props;

  const markerProps: MarkerProps = {
    longitude: vehicle.longitude,
    latitude: vehicle.latitude
  };

  return (
    <Marker {...markerProps} style={{ maxWidth: '28px', height: 'auto' }}>
      <AvSiennaIcon
        sx={{
          width: '100%',
          height: 'auto',
          transform: `rotate(${vehicle.heading.toFixed(0)}deg)`
        }}
      />
    </Marker>
  );
};

interface VehicleMarkerProps {
  vehicle: Vehicle;
  platform: Platform | undefined;
}

const VehicleMarkers = (props: VehicleMarkersProps) => {
  const { mayId } = props;
  const dispatch = useAppDispatch();
  const vehicles: Vehicle[] = useAppSelector((state) => [
    state.vehicles[mayId]
  ]);

  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const session = await Auth.currentSession();
        setAccessToken(session.getAccessToken().getJwtToken());
      } catch (error) {
        console.error('Failed to get access token', error);
      }
    };

    getAccessToken();
  }, []);

  const pollVehicleUpdates = async () => {
    if (accessToken) {
      const vehicles = await getVehicles(accessToken);
      dispatch(setVehicleUpdates(vehicles));
    }
  };

  useInterval(pollVehicleUpdates, 1000);

  return (
    <>
      {vehicles
        .filter((vehicle) => !!vehicle)
        .map((vehicle) => {
          return (
            <VehicleMarker
              key={vehicle.vehicleId}
              vehicle={vehicle}
              platform='sienna-v1'
            />
          );
        })}
    </>
  );
};

interface VehicleMarkersProps {
  mayId: string;
}

export default VehicleMarkers;
